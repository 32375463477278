import * as React from "react"
import Page from "../components/Page"
import Section from "../components/Section"
import Typography from "../components/Typography"

// markup
const NotFoundPage = () => {
  return (
    <Page title="Not Found">
      <Section theme="secondary">
        <Typography type="hero" preset="1">Oops!</Typography>
        <Typography preset="1">
          Sorry, that page doesn't seem to exist. Check the url and try again.
        </Typography>
      </Section>
    </Page>
  )
}

export default NotFoundPage
